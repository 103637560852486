
import React, { useState, useEffect } from 'react';
import Fade from 'react-reveal/Fade';
import '../css/pagination.css'
const Projects = ({ show, delay = 500, slideIndex }) => {
    const [isMounted, setIsMounted] = useState(show);
    useEffect(() => {
        const timeout = setTimeout(() => setIsMounted(show), delay);
        return () => clearTimeout(timeout);
    }, [show, delay, slideIndex]);
    const projects = [
        {
            index: 0,
            name: 'Cardify',
            links: [
                //{
                //    name: 'wesbiteLink',
                //    link: 'https://task-manager.prayuj.tech/'
                //},
                {
                    name: 'githubLink',
                    link: 'https://github.com/Ibzo1/Cardify'
                }
            ],
            desc: `Cardify produces beautiful Quizlet-style AI generated flashcards when imputted with a specific subject of interest; this webapp was built on a Flask backend with a frontend in React.`,
            languages: 'Flask, Python, React, Javascript, Bootstrap, HTML, CSS'
        },
        {
            index: 1,
            name: 'MagicMail (IP)',
            links: [
               
                {
                    name: 'githubLink',
                    link: 'https://github.com/Ibzo1/Mail_Magic'
                }
            ],
            desc: `MagicMail is an exciting project that is still in the works; this extension provides users with the ability to "professionalize" emails they are working on and create touch-ups with the click of a button overlaying their respective mail client. This project is using a Flask backend and a React frontend currently coupled with the Chorme Extension API `,
            languages: 'NodeJS, Socket.io'
        },
        {
            index: 2,
            name: 'Protein Interactions',
            links: [
               
            ],
            desc: `This project stemmed from my opportunity with Inspirit AI where my team and I created a presentation on how machine learning can be used in scientific applications such as locating protein interactions by applying various Machine Learning techniques.`,
            languages: 'Mongo, Express, React, Node'
        },
        {
            index: 3,
            name: 'ToDo-List',
            links: [
                {
                    name: 'githubLink',
                    link: 'https://github.com/Ibzo1/TodoList'
                }
            ],
            desc: `This To-Do List is a simple project allowing users to input, update and delete task entries. Using Javascript, HTML, and CSS I was able to construct a modern interactive webapp.`,
            languages: 'Python, React'
        },
        {
            index: 4,
            name: 'Pong',
            links: [

                
                {
                    name: 'githubLink',
                    link: 'https://github.com/Ibzo1/Pong-'
                }
            ],
            desc: `The classic Pong game is one of my first projects where I used pure Python to recreate Pong, a one player version with a bot for competition.`,
            languages: 'React, Flask'
        },

        

    ]

    const iconObjects = {
        wesbiteLink: 'fas fa-external-link-alt',
        githubLink: 'fab fa-github-square',
        linkedinLink: 'fab fa-linkedinLink',
        youtubeLink: 'fab fa-youtube',
        paperLink: 'fas fa-newspaper'
    }

    const style = {
        "width": "100%",
        "alignSelf": "center",
        "justifyContent": "space-evenly",
        "transitionDelay": "600ms"
    }

    const projectsLength = projects.length

    return (
        <div className="h-100 display-flex flex-direction-column justify-content-center container">
            <Fade bottom in={isMounted}>
                <span className="display-flex align-items-center side-margin">
                    <h5><span className="accent-style index">[3]</span></h5>
                    <h3>Projects</h3>
                    <span style={{ marginLeft: 'auto', display: 'flex', fontStyle: "italic" }}>
                        <span className="accent-style">
                            <h6>{slideIndex + 1}</h6>
                        </span>
                        <span>
                            <h6>/{projectsLength}</h6>
                        </span>
                    </span>
                </span>
            </Fade>
            {projects.map((project, index) => (
                <div className="full-page-slide fp-auto-height" style={{ width: '100%' }} key={index}>
                    <div className="display-flex justify-content-center align-items-center container">
                        <div className="display-flex justify-content-center flex-direction-column slide side-margin" >
                            <Fade bottom in={isMounted} delay={200}>
                                <h5>{project.name}</h5>
                            </Fade>
                            <Fade bottom in={isMounted} delay={300}>
                                <p>
                                    {project.desc}
                                </p>
                            </Fade>

                            <div className="project-link-container">
                                {project.links.map((linkObj, index) =>
                                    <Fade bottom when={isMounted} delay={index * 100 + 500} key={index}>
                                        <a href={linkObj.link} style={{ width: 'max-content' }} target="_blank" rel="noreferrer" className="project-link content-hover-link">
                                            <i className={iconObjects[linkObj.name]}></i>
                                        </a>
                                    </Fade>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {<Fade bottom in={isMounted} delay={600}>
                <div className="display-flex align-items-center" style={style}>
                    <span id="add-blink-class" className={`cursor-pointer accent-style align-self-center ${slideIndex === 0 ? 'slide-move-disabled' : ''}`} onClick={() => {
                        if (window.fullpage_api.moveSlideLeft) {
                            window.fullpage_api.moveSlideLeft()
                        }
                    }
                    }><h3><i className="fas fa-chevron-left"></i></h3></span>
                    <span id="add-blink-class" className={`cursor-pointer accent-style align-self-center ${slideIndex === projectsLength - 1 ? 'slide-move-disabled' : ''}`} onClick={() => {
                        if (window.fullpage_api.moveSlideRight) {
                            window.fullpage_api.moveSlideRight()
                        }
                    }
                    }><h3><i className="fas fa-chevron-right"></i></h3></span>
                </div>
            </Fade>}
        </div>
    )
}

export default Projects;